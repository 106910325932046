<template>
  <div
    ref="main-top"
    class="main-top mb-5 mb-md-10"
    :style="{ 'padding-top': `${navTotalHeight}px` }"
  >
    <v-container class="inner-container my-8 mb-md-10 mt-md-5">
      <div class="ecvSearchBox">
        <SearchBox :syncRouteQuery="true" />
      </div>
    </v-container>
    <ecvWaveR class="main-top_wave" />
  </div>
</template>

<script>
import ecvWaveR from "/src/components/wave/ecvWaveR.vue";
export default {
  props: {},
  components: {
    ecvWaveR,
  },
  data: () => ({
    data: "",
  }),
  computed: {
    navTotalHeight() {
      return this.$store.getters["base/navTotalHeight"];
    },
  },
};
</script>